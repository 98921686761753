@import 'layout/base.less';
@import './horizontal-screen';

.n-layout-head {
  height: 60px;
  width: 100%;
  background: url(../img/headbg.png) no-repeat left #ec488d;
  background-size: auto 100%;
  .layout-head-brand {
    display: block;
    height: 100%;
    padding: 7px 0 3px 60px;
    float: left;
    margin-right: 30px;
    img {
      height: 44px;
      width: auto;
      display: inline-block;
    } //brand logo 高度设置 宽度自适应
  }
  .layout-head-menu {
    display: inline-block;
    float: left;
    line-height: 60px;
    font-size: 14px;
    color: #fff;
    margin-right: 30px;
    cursor: pointer;
    position: relative;
    .qrcode-popover-content {
      position: absolute;
      width: 110px;
      height: 120px;
      top: 60px;
      left: -30px;
      opacity: 0;
      z-index: 1111;
      visibility: hidden;
      transition: opacity 0.5s;
      text-align: center;
      background: #ffffff;
      padding: 10px;
      box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.5);
      p {
        margin: 0;
        color: #4b4b4b;
        font-size: 12px;
        letter-spacing: 1px;
        line-height: 22px;
      }
      img {
        width: 80px;
        height: 80px;
        display: inline-block;
      }

      &:before {
        top: 0;
        right: 50%;
        width: 0;
        height: 0;
        letter-spacing: 1px;
        border: 6px solid transparent;
        border-bottom: 6px solid #fff;
        margin-top: -12px;
        margin-right: -6px;
      }
    }
    &:hover {
      .qrcode-popover-content {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
.i-redact-choice {
  min-height: 115px;
  background: #fff;
  white-space: nowrap;
  color: #383838;
  border-bottom: 1px solid #f1f1f1;
  .i-left {
    //width:17%;

    float: left;
    height: 100%;
    min-width: 160px;
    min-height: inherit;
    padding-left: 12px;
    padding-right: 0px;
  }
  .i-right {
    width: 100%;
    height: 100%;
    //float:left;
    min-height: inherit;
    white-space: nowrap;
    .platform-choice {
      padding-left: 12px;
      padding-right: 0px;
      overflow: hidden;
      height: 100%;
    }
    .time-c-list {
      li,
      .item {
        //时间选择结构 子元素 可以是li 也可以通过设置类item
        cursor: pointer;
        span {
          display: inline-block;
          height: 18px;
          padding: 0 10px;
          font-family: PingFang-SC-Regular;
          font-size: 10px;
          color: #646464;
          border-radius: 1px;
          border: 1px solid rgba(0, 0, 0, 0);
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0);
        }
        &.text {
          span {
            padding: 0;
            input {
              padding: 0 10px;
            }
          }
        }

        input[type='text'] {
          display: inline-block;
          width: 70px;
          border: none;
          font-size: 10px;
          color: #646464;
          &::-webkit-input-placeholder {
            color: #646464;
            font-size: 10px;
          }
        }
        &.action {
          span {
            color: #ff006b;
            background: #f8f8f8;
            border: 1px solid #d8d8d8;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
          }
          input[type='text']::-webkit-input-placeholder {
            color: #c4c4c4;
          }
        }
      }
    } //time选择  通过添加删除 类action 实现状态
  }
  .title {
    line-height: 40px;
    font-family: PingFang-SC-Regular;
    font-size: 10px;
  }
  .line-r {
    border-right: 1px solid #f1f1f1;
    float: left;
    min-height: inherit;
  }
  .time-choice {
    padding-left: 12px;
    padding-right: 12px;
    height: 100%;
  }
  .choice-list {
    //设备，平台下选择 样式 结构：父元素  通过为类item元素 添加删除类action 实现状态
    white-space: nowrap;
    cursor: pointer;
    overflow: hidden;
    .item {
      //子元素 可选择项
      width: 50px;
      height: 60px;
      float: left;
      margin-right: 12px;
      font-size: 10px;
      color: #383838;
      text-align: center;
      border-radius: 1px;
      border: 1px solid rgba(0, 0, 0, 0);
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0);
      span {
        display: block;
        line-height: 25px;
        height: 25px;
      }
      .brand {
        //brand 图片设置
        height: 35px;
        overflow: hidden;
        line-height: 35px;
        img {
          display: inline-block;
          max-height: 30px;
          max-width: 36px;
          margin: 0 auto;
          vertical-align: middle;
        }
      }
      &.action {
        //状态
        background: #f8f8f8;
        border: 1px solid #d8d8d8;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
      }
    }
    .e-iphone {
      .brand img {
        background: url(../img/iphone.png) no-repeat;
        width: 12px;
        height: 21px;
        margin: 11px auto 0;
      }
    }
    .e-ipad {
      .brand img {
        background: url(../img/ipad.png) no-repeat;
        width: 22px;
        height: 30px;
        margin: 4px auto 0;
      }
    }
  }
  .download {
    //下载元素样式
    cursor: pointer;
    min-height: inherit;
    float: right;
    width: 75px;
    margin-right: 30px;
    background: url(../img/download.png) no-repeat center;
    background-size: 74px auto;
  }
}
.i-main {
  width: 100%;
  overflow: hidden;
  min-height: 800px;
  background: #f8f8f8;
  display: flex;
  .i-left {
    width: 15%;
    min-height: inherit;
    background: #eaeaea;
    display: relative;
    .m-uploading {
      //点击上传元素样式
      width: 150px;
      height: 150px;
      margin: 25px auto 0;
      background: url(../img/up.png) no-repeat #f8f8f8;
      background-position: center 50px;
      border: 1px solid #d8d8d8;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
      font-size: 12px;
      color: #383838;
      text-align: center;
      position: relative;
      input {
        display: none;
      }
      span {
        margin-top: 85px;
        display: block;
        pointer-events: none;
      }
      label {
        display: inline-block;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
      img {
        position: absolute;
        display: block;
        pointer-events: none;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }
    }
  }
  .i-right {
    width: 85%;
    text-align: center;
    padding: 40px 20px;
    height: 800px;
    position: relative;
    .demonstrate {
      //展示区样式
      transform: scale(0.5);
      transform-origin: top left;
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      //max-width:100%;
      border: 1px solid #d8d8d8;
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
    }
  }
}
.ad-play-time,
.top-bar {
  display: none;
}
.zoom-wrap {
  position: fixed;
  bottom: 10px;
  right: 10px;
  font-size: 12px;
  color: #999;
}

/*视频配置页*/
.edit-main {
  display: flex;
  .edit-area {
    width: 33.333%;
    border-right: 2px solid #d3d3d3;
    height: 100vh;
    padding: 24px;
    color: #495060;
    font-size: 13px;
    & > h3 {
      margin-bottom: 10px;
      font-size: 15px;
    }
  }
  .iphone {
    background: url(../img/iphone_bg.png) no-repeat;
    width: 436px;
    background-size: 100%;
    padding-top: 115px;
    margin: 0 auto;
    height: 890px;
  }
  .android {
    background: url(../img/android_bg.jpg) no-repeat;
    width: 413px;
    background-size: 100%;
    padding-top: 63px;
    margin: 0 auto;
    height: 846px;
  }
  .preview-iframe {
    width: 375px;
    height: 667px;
    height: 720px;
    display: block;
    border: 1px solid #ddd;
    margin: 0 auto;
    overflow-x: hidden;
    box-sizing: content-box;
  }
  .form-item {
    margin-bottom: 20px;
    input[type='text'],
    textarea {
      display: inline-block;
      width: 100%;
      height: 32px;
      line-height: 1.5;
      padding: 4px 7px;
      font-size: 12px;
      border: 1px solid #dddee1;
      border-radius: 4px;
      color: #495060;
      background-color: #fff;
      background-image: none;
      position: relative;
      cursor: text;
      transition: border 0.2s ease-in-out, background 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    }
    textarea {
      min-height: 200px;
    }
    .form-tips {
      font-size: 12px;
    }
    .upload-btn {
      input[type='file'] {
        display: none;
      }
    }
    label {
      font-weight: bold;
    }
  }
  .form-btn {
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    line-height: 1.5;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 6px 15px;
    font-size: 12px;
    border-radius: 4px;
    transition: color 0.2s linear, background-color 0.2s linear, border 0.2s linear, box-shadow 0.2s linear;
    color: #fff;
    background-color: #ec488d;
    border-color: #ec488d;
  }
  .qrcode-area,
  .link-area {
    width: 200px;
    height: 200px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin: 0 auto;
    margin-bottom: 20px;
    background: #fff;
  }
  .link-area {
    padding: 10px;
    word-break: break-all;
  }
}
.edit-platform {
  overflow: hidden;
  padding: 10px 5px;
  .platform-item {
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;
    width: 60px;
    height: 60px;
    text-align: center;
    padding: 5px;
    border-radius: 4px;
    font-size: 12px;
    .brand {
      height: 30px;
      margin-bottom: 6px;
    }
    img {
      width: 30px;
      vertical-align: middle;
    }
    &:hover,
    &.selected {
      outline: 1px solid #ec488d;
    }
  }
}
@media screen and (max-width: 1300px) {
  .edit-area.preview {
    display: none;
  }
  .edit-area {
    width: 50% !important;
  }
}
.item-block {
  width: 42px;
  &.selected {
    border-radius: 4px;
    border: 1px solid #ec488d;
  }
}

.repertoire-choice {
  display: none;
}
