.horizontal-screen {
  &.ios {
    .demonstrate {
      canvas {
        width: 2436px;
        height: 1125px;
      }
    }
  }
  &.android {
    .demonstrate {
      canvas {
        width: 1326px;
        height: 720px;
      }
    }
  }
}
